import React, { Fragment } from 'react'

export default class Gauge extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false,
      position: 0
    }
  }

  componentDidMount() {
    if (this.props.position) {
      this.setState({
        position: this.props.position
      })
    }

    window.addEventListener('mouseup', this.eventMouseUp, true)
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.eventMouseUp, true)
  }

  eventMouseUp = () => {
    if (this.state.isDisplay) {
      this.setState({ isActive: false })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.position !== this.props.position &&
      this.props.position !== this.state.position
    ) {
      this.setState({
        position: this.props.position
      })
    }
  }

  onMouse = (e, isForce = false) => {
    if (this.state.isActive || isForce) {
      let currentTargetRect = e.currentTarget.getBoundingClientRect()
      const width = currentTargetRect.width
      const positionX = e.pageX - currentTargetRect.left

      const step = this.props.step ? this.props.step : 1

      let position =
        (positionX * 100) / width - (((positionX * 100) / width) % step)

      this.setState(
        {
          position
        },
        () => (this.props.onChange ? this.props.onChange(position) : null)
      )
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.onEdit ? (
          <span
            style={{
              display: 'inline-block',
              fontSize: '14px',
              fontWeight: 'bold',
              color: 'var(--blue)',
              marginBottom: '16px',
              cursor: 'pointer',
              textDecoration: 'underline'
            }}
            onClick={this.props.onEdit}
          >
            {this.props.title}
          </span>
        ) : (
          <span
            style={{
              display: 'inline-block',
              fontSize: '14px',
              fontWeight: 'bold',
              color: 'var(--black)',
              marginBottom: '16px'
            }}
          >
            {this.props.title}
          </span>
        )}
        <div>
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '22px',
              backgroundImage:
                this.props.colors.length === 3
                  ? `linear-gradient(to right, ${this.props.colors[0]} 0%, ${this.props.colors[1]} 50%, ${this.props.colors[2]} 100%)`
                  : `linear-gradient(to right, ${this.props.colors[0]}, ${this.props.colors[1]})`,
              borderRadius: '8px'
            }}
            onMouseMove={(e) => this.onMouse(e)}
            onClick={(e) => this.onMouse(e, true)}
            onMouseDown={(e) => {
              e.preventDefault()

              this.setState({
                isActive: true
              })
            }}
          >
            {this.props.min ? (
              <Fragment>
                <span
                  style={{
                    position: 'absolute',
                    left: this.props.min + '%',
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    top: '-18px',
                    fontSize: '10px'
                  }}
                >
                  MIN
                </span>
                <div
                  style={{
                    position: 'absolute',
                    width: '4px',
                    height: '28px',
                    borderRadius: '4px',
                    top: '-3px',
                    backgroundColor: 'black',
                    left: this.props.min + '%',
                    transform: 'translateX(-50%)'
                  }}
                />
              </Fragment>
            ) : null}
            {this.props.max ? (
              <Fragment>
                <span
                  style={{
                    position: 'absolute',
                    left: this.props.max + '%',
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    top: '-18px',
                    fontSize: '10px'
                  }}
                >
                  MAX
                </span>
                <div
                  style={{
                    position: 'absolute',
                    width: '4px',
                    height: '28px',
                    borderRadius: '4px',
                    top: '-3px',
                    backgroundColor: 'black',
                    left: this.props.max + '%',
                    transform: 'translateX(-50%)'
                  }}
                />
              </Fragment>
            ) : null}
            {this.props.noText ? null : (
              <span
                style={{
                  position: 'absolute',
                  left: this.state.position + '%',
                  transform: 'translateX(-50%)',
                  textAlign: 'center',
                  top: '-16px',
                  fontSize: '10px',
                  fontWeight: 'bold',
                  transition: '0.3s'
                }}
              >
                {parseInt(this.state.position)}%
              </span>
            )}
            <div
              className='cursor-pointer'
              style={{
                position: 'absolute',
                bottom: '-6px',
                width: 0,
                height: 0,
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderBottom: '18px solid black',
                transform: 'translateX(-50%)',
                left: this.state.position + '%'
              }}
            ></div>
          </div>
        </div>
      </div>
    )
  }
}
