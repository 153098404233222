import React from 'react'
import classNames from 'classnames'
import style from './style.css'

class Loader extends React.Component {
  componentDidUpdate() {
    if (this.props.show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }

  render() {
    if (!this.props.show) return null

    return (
      <div className={classNames(style.loader_container, this.props.className)}>
        <div
          className={classNames(
            style.loader_element,
            this.props.classNameChildren
          )}
        >
          <div className={style.loader}>
            <span />
            <span />
            <span />
            <span />
          </div>
          {this.props.message ? <p>{this.props.message}</p> : null}
        </div>
      </div>
    )
  }
}

export default Loader
