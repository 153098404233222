import React from 'react'
import DropzoneCMP from 'react-dropzone'
import style from './style.css'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFile,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileImage,
  faFileCsv,
  faTrash
} from '@fortawesome/free-solid-svg-icons'

export default class DropzoneMultiple extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoad: false,
      files: []
    }
  }

  resizeImage = (base64, mime, callback = () => {}) => {
    if (
      this.props.maxImageSize &&
      ['image/png', 'image/jpeg', 'image/jpg'].includes(mime)
    ) {
      const image = new Image()
      image.src = base64
      image.onload = () => {
        let initialWidth = image.width
        let initialHeight = image.height
        let finalWidth = 0
        let finalHeight = 0

        if (initialWidth > initialHeight) {
          if (initialWidth > this.props.maxImageSize) {
            finalWidth = this.props.maxImageSize
            finalHeight = (finalWidth * initialHeight) / initialWidth
          } else {
            finalWidth = initialWidth
            finalHeight = initialHeight
          }
        } else {
          if (initialHeight > this.props.maxImageSize) {
            finalHeight = this.props.maxImageSize
            finalWidth = (finalHeight * initialWidth) / initialHeight
          } else {
            finalWidth = initialWidth
            finalHeight = initialHeight
          }
        }

        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = finalWidth
        canvas.height = finalHeight
        ctx.drawImage(image, 0, 0, finalWidth, finalHeight)
        base64 = canvas.toDataURL(mime, 0.9)

        callback(base64)
      }
      image.onerror = () => {
        callback(base64)
      }
    } else {
      callback(base64)
    }
  }

  handleDeleteFile = (idx) => {
    let files = this.state.files

    files.splice(idx, 1)

    this.setState({
      files
    })
  }

  getFileComponent = (idx) => {
    return (
      <div
        key={idx}
        className={classNames(style.dropzone_multiple_item, 'not-click')}
        onClick={() => this.handleDeleteFile(idx)}
      >
        <div className={classNames(style.delete_item_container)}>
          <FontAwesomeIcon icon={faTrash} />
        </div>
        <FontAwesomeIcon icon={this.getIcon(this.state.files[idx].extension)} />
      </div>
    )
  }

  hasParentClass = (child, current, classname) => {
    if (
      child.className &&
      typeof child.className === 'string' &&
      child.className.includes(classname)
    ) {
      return true
    } else {
      if (child.parentNode == current || child == current) {
        return false
      } else {
        return this.hasParentClass(child.parentNode, current, classname)
      }
    }
  }

  getFile = (file, files) => {
    let name = file.name.split('.')
    if (this.checkExtension(name[name.length - 1])) {
      if (
        !this.props.maxFileSize ||
        this.props.maxFileSize * 1000000 > file.size
      ) {
        const reader = new window.FileReader()

        reader.onload = () => {
          this.resizeImage(reader.result, file.type, (base64) => {
            let tmpFiles = this.state.files

            tmpFiles.push({
              base64,
              mime: file.type,
              name: file.name,
              extension: name[name.length - 1],
              size: file.size
            })

            this.setState(
              {
                files: tmpFiles
              },
              () => {
                this.currentFile += 1
                this.nextFile(files)
              }
            )
          })
        }

        reader.readAsDataURL(file)
      } else {
        alert(
          `Fichier trop volumineux - Taille maxium ${this.props.maxFileSize}Mo`
        )
        this.currentFile += 1
        this.nextFile(files)
      }
    } else {
      this.currentFile += 1
      this.nextFile(files)
    }
  }

  nextFile = (files) => {
    if (this.currentFile === files.length) {
      if (this.props.onDrop) {
        this.props.onDrop(this.state.files)
      }
    } else {
      this.getFile(files[this.currentFile], files)
    }
  }

  handleDrop = (files) => {
    if (files && files.length) {
      this.currentFile = 0
      this.nextFile(files)
    } else {
      alert('Une erreur est survenue')
    }
  }

  getIcon = (extension) => {
    if (['pdf'].includes(extension)) {
      return faFilePdf
    } else if (['doc', 'docx'].includes(extension)) {
      return faFileWord
    } else if (['xls', 'xlsx'].includes(extension)) {
      return faFileExcel
    } else if (['png', 'jpg', 'jpeg'].includes(extension)) {
      return faFileImage
    } else if (['csv'].includes(extension)) {
      return faFileCsv
    } else {
      return faFile
    }
  }

  addFile = (file) => {
    let tmpFiles = this.state.files

    tmpFiles.push(file)

    this.setState({ files: tmpFiles }, () => {
      if (this.props.onDrop) {
        this.props.onDrop(this.state.files)
      }
    })
  }

  addFiles = (files) => {
    let tmpFiles = files

    this.setState({ files: tmpFiles }, () => {
      if (this.props.onDrop) {
        this.props.onDrop(this.state.files)
      }
    })
  }

  checkExtension = (extension) => {
    extension = extension ? extension.toLowerCase() : ''

    if (!this.props.extensions || this.props.extensions.includes(extension)) {
      return true
    } else {
      return false
    }
  }

  render() {
    return (
      <DropzoneCMP onDrop={this.handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <section
            className={classNames(
              style.dropzone_multiple_container,
              this.props.className
            )}
          >
            <div
              {...getRootProps({
                onClick: (e) => {
                  if (
                    this.hasParentClass(e.target, e.currentTarget, 'not-click')
                  ) {
                    e.stopPropagation()
                  }
                }
              })}
              className={classNames(style.dropzone_multiple_items)}
            >
              {!this.state.files.length ? (
                <span className={classNames(style.background_text)}>
                  Glisser et déposer vos fichiers ou cliquer pour en
                  sélectionner...{' '}
                  {this.props.extensions
                    ? `Formats acceptés : ${this.props.extensions.join(', ')}`
                    : ''}{' '}
                  {this.props.maxFileSize
                    ? ` - Taille maximale ${this.props.maxFileSize}Mo`
                    : ''}
                </span>
              ) : null}
              <input {...getInputProps()} />
              {this.state.files.map((file, idx) => {
                return this.getFileComponent(idx)
              })}
            </div>
          </section>
        )}
      </DropzoneCMP>
    )
  }
}
