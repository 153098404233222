import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class FormTextarea extends React.Component {
  handleKeyDown(e) {
    e.target.style.height = 'inherit'
    e.target.style.height = e.target.scrollHeight + 'px'
  }

  render() {
    return (
      <div
        className={classNames(
          style.form_textarea_container,
          this.props.className
        )}
      >
        {this.props.title ? (
          <span>
            {this.props.title}{' '}
            {this.props.required ? <span className='red'>*</span> : ''}
          </span>
        ) : (
          ''
        )}
        <textarea
          spellCheck={true}
          lang={'fr'}
          onKeyDown={!this.props.noResize ? this.handleKeyDown : null}
          onBlur={this.props.onBlur}
          rows={this.props.rows}
          cols={this.props.cols}
          className={style.form_textarea}
          type={this.props.type}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          value={this.props.value}
          name={this.props.name}
          required={this.props.required}
          disabled={this.props.disabled}
          style={this.props.style}
        />
        {this.props.error ? (
          <span className={style.form_textarea_error}>{this.props.error}</span>
        ) : (
          ''
        )}
      </div>
    )
  }
}
