import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class FormCheckbox extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          style.form_checkbox_container,
          this.props.className
        )}
      >
        <label className={classNames(this.props.className)}>
          {this.props.text}
          <input
            type='checkbox'
            name={this.props.name}
            checked={this.props.checked}
            onChange={this.props.onChange}
          />
          <span className={style.form_checkbox_mark} />
        </label>
      </div>
    )
  }
}
