import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import style from './style.css'
import classNames from 'classnames'

export default class FullModal extends React.Component {
  componentDidUpdate() {
    if (this.props.show) {
      document.documentElement.style.overflow = 'hidden'
      document.body.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'unset'
      document.body.style.overflow = 'unset'
    }
  }

  render() {
    if (!this.props.show) {
      return null
    }
    return (
      <div className={classNames(style.modal, this.props.className)}>
        <div className={style.full_modal_container}>
          <div className={style.modal_valid} onClick={this.props.onValid}>
            {this.props.onValid ? <FontAwesomeIcon icon={faCheck} /> : ''}
          </div>
          <div className={style.modal_close} style={this.props.closeStyle} onClick={this.props.onClose}>
            {this.props.iconClose ? (
              this.props.iconClose
            ) : (
              <FontAwesomeIcon icon={faTimes} />
            )}
          </div>
          {this.props.onCustom ? (
            <div className={style.modal_custom} onClick={this.props.onCustom}>
              <FontAwesomeIcon icon={this.props.customIcon} />
            </div>
          ) : null}
          {this.props.title ? (
            <div className={style.full_modal_header}>
              <h2>{this.props.title}</h2>
            </div>
          ) : null}
          <div
            className={classNames(
              this.props.noPadding
                ? style.full_modal_body_no_padding
                : style.full_modal_body,
              this.props.noOverflow ? style.full_modal_body_no_overflow : {}
            )}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}
