import React from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class FormInput extends React.Component {
  render() {
    if (this.props.children) {
      return (
        <div
          className={classNames(
            style.form_input_container,
            this.props.className
          )}
          style={this.props.style}
        >
          {this.props.title ? (
            <span className={this.props.classNameTitle}>
              {this.props.title}{' '}
              {this.props.required ? <span className='red'>*</span> : ''}
            </span>
          ) : (
            ''
          )}
          <div className={style.form_input_container_icon}>
            <div>{this.props.children}</div>
            <input
              style={this.props.styleInput}
              className={style.form_input_icon}
              type={this.props.type}
              placeholder={this.props.placeholder}
              onChange={this.props.onChange}
              onKeyDown={this.props.onKeyDown}
              onBlur={this.props.onBlur}
              value={this.props.value}
              name={this.props.name}
              required={this.props.required}
              min={this.props.min ? this.props.min : ''}
              max={this.props.max ? this.props.max : ''}
            />
          </div>
          {this.props.error ? (
            <span className={style.form_input_error}>{this.props.error}</span>
          ) : (
            ''
          )}
        </div>
      )
    } else {
      return (
        <div
          className={classNames(
            style.form_input_container,
            this.props.className
          )}
          style={this.props.style}
        >
          {this.props.title ? (
            <span className={this.props.classNameTitle}>
              {this.props.title}{' '}
              {this.props.required ? <span className='red'>*</span> : ''}
            </span>
          ) : (
            ''
          )}
          <input
            style={this.props.styleInput}
            className={style.form_input}
            type={this.props.type}
            placeholder={this.props.placeholder}
            onChange={this.props.onChange}
            onKeyDown={this.props.onKeyDown}
            onBlur={this.props.onBlur}
            value={this.props.value}
            name={this.props.name}
            required={this.props.required}
            disabled={this.props.disabled}
            min={this.props.min ? this.props.min : ''}
            max={this.props.max ? this.props.max : ''}
          />
          {this.props.error ? (
            <span className={style.form_input_error}>{this.props.error}</span>
          ) : (
            ''
          )}
        </div>
      )
    }
  }
}
