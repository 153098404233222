import React from 'react'
import style from './style.css'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'

export default class Th extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sort: false,
      desc: false
    }
  }

  handleSortClick = () => {
    this.setState({ desc: !this.state.desc, sort: true }, () => {
      if (this.props.onSort) {
        this.props.onSort(this.state.desc)
      }
    })
  }

  getSortButton() {
    if (this.state.sort === false) {
      return (
        <div className={style.table_sort}>
          <div>
            <FontAwesomeIcon icon={faCaretUp} />
          </div>
          <div>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
        </div>
      )
    } else if (this.state.desc === true) {
      return (
        <div className={style.table_sort}>
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
      )
    } else if (this.state.desc === false) {
      return (
        <div className={style.table_sort}>
          <FontAwesomeIcon icon={faCaretUp} />
        </div>
      )
    }
  }

  render() {
    var thClass = classNames({
      [style.th]: true,
      [style.pointer]: !!this.props.sort,
      [style.spaceLeft]: !!this.props.spaceLeft,
      [style.spaceRight]: !!this.props.spaceRight,
      [style.noSpaceLeft]: !!this.props.noSpaceLeft,
      [style.noSpaceRight]: !!this.props.noSpaceRight,
      [style.radiusTopLeft]: !!this.props.radiusTopLeft,
      [style.radiusTopRight]: !!this.props.radiusTopRight,
      [style.radiusBottomLeft]: !!this.props.radiusBottomLeft,
      [style.radiusBottomRight]: !!this.props.radiusBottomRight,
      [style.hide]: !!this.props.hide,
      [this.props.className]: !!this.props.className
    })
    return (
      <th
        rowSpan={this.props.rowSpan}
        colSpan={this.props.colSpan}
        className={thClass}
        onClick={this.props.sort ? this.handleSortClick : null}
        style={this.props.style}
      >
        <div className={style.table_flex}>
          <span
            style={!this.props.sort ? { margin: 'auto' } : {}}
            dangerouslySetInnerHTML={this.props.dangerouslySetInnerHTML}
          >
            {this.props.children}
          </span>
          {this.props.sort ? this.getSortButton() : ''}
        </div>
      </th>
    )
  }
}
