import React from 'react'
import style from './style.css'
import classNames from 'classnames'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

import FormCheckbox from "../form-checkbox/FormCheckbox"

export default class FormSelectMultiples extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDisplay: false,
    }

    this.inputRef = React.createRef()
  }

  onClick = (element) => {
    {
      let value = this.props.value

      if (value.includes(element.value)) {
        value.splice(value.indexOf(element.value), 1)
      } else {
        value.push(element.value)
      }

      this.props.onChange(value)
    }
  }

  render() {
    return (
      <div
        className={classNames(
          style.form_select_multiples_container,
          this.props.className
        )}
      >
        {this.props.title ? (
          <span className={this.props.classNameTitle}>
            {this.props.title}{' '}
            {this.props.required ? <span className='red'>*</span> : ''}
          </span>
        ) : (
          ''
        )}
        <div ref={this.inputRef} className={style.form_select_multiples} onClick={() => this.setState({ isDisplay: !this.state.isDisplay })}>
          <span>{this.props.value && this.props.value.length ? this.props.options.filter(element => (
            this.props.value.includes(element.value)
          )).map((element, idx) => `${idx ? ";" : ""}${element.text}`) : this.props.default}</span>
          <div>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
        </div>
        {this.state.isDisplay ? <div style={{
          top: this.inputRef.current.getBoundingClientRect().y + 60,
          left: this.inputRef.current.getBoundingClientRect().x,
          width: this.inputRef.current.offsetWidth,
        }} className={style.form_select_values}>
          <div>
            {this.props.options.map((element, idx) => (
              <div key={idx} onClick={() => this.onClick(element)}>
                <FormCheckbox onChange={() => this.onClick(element)} checked={this.props.value.includes(element.value)} text={element.text} />
              </div>
            ))}
          </div>
          <div className={classNames('mt-auto d-flex', style.form_select_multiples_bottom)}>
            <span onClick={() => this.props.onChange([])}>Effacer</span>
            <span className='ml-auto' onClick={() => this.setState({ isDisplay: false })}>Valider</span>
          </div>
        </div> : null}
      </div>
    )
  }
}
