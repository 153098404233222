import React, { Fragment } from 'react'
import style from './style.css'
import classNames from 'classnames'
import Button from '../button/Button'
import Modal from '../modal/Modal'
import { Row, Col } from 'react-flexbox-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'universal-cookie'

export default class Cookie extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      showInfo: false
    }
    this.cookies = new Cookies()
  }

  componentDidMount() {
    if (
      this.props.force ||
      (this.cookies.get(`${this.props.name}-cookies`) !== '1' &&
        this.cookies.get(`${this.props.name}-cookies`) !== '2')
    ) {
      this.setState({ show: true })
    } else {
      this.setState({ show: false })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.force !== this.props.force ||
      prevProps.name !== this.props.name
    ) {
      if (
        this.cookies.get(`${this.props.name}-cookies`) !== '1' &&
        this.cookies.get(`${this.props.name}-cookies`) !== '2'
      ) {
        this.setState({ show: true })
      } else {
        this.setState({ show: false })
      }
    }
  }

  render() {
    return (
      <Modal
        title={
          this.props.title || 'Données personnelles & utilisation des cookies'
        }
        show={this.state.show}
        onClose={this.props.onClose}
        disableClose={true}
        large
      >
        {this.props.message ? (
          this.props.message
        ) : (
          <Fragment>
            <p>
              Nous utilisons des cookies afin d’améliorer votre expérience sur
              le site. Ces cookies servent à personnaliser le contenu du site,
              en mesurer la fréquentation et les performances.
            </p>
            <p>
              En cliquant sur le bouton "Accepter" vous acceptez tous les
              cookies. Vous pouvez aussi en savoir plus à tout moment via le
              lien "RGPD & Cookies" accessible dans le pied de page.
            </p>
          </Fragment>
        )}

        {this.props.infos ? (
          <>
            <a
              href='/'
              style={{ textAlign: 'left', margin: '16px 0' }}
              onClick={(event) => {
                event.preventDefault()
                this.setState((state) => ({
                  ...state,
                  showInfo: !state.showInfo
                }))
              }}
            >
              En savoir plus{' '}
              <FontAwesomeIcon
                icon={this.state.showInfo ? faCaretUp : faCaretDown}
              />
            </a>
            {this.state.showInfo ? (
              <div style={{ margin: '16px 0' }}>{this.props.infos}</div>
            ) : null}
          </>
        ) : null}

        <b>
          Nos cookies ont pour seul et unique but de faire fonctionner le
          service
        </b>

        <Row style={{ margin: '16px 0' }}>
          <Col
            xs={12}
            md={this.props.isReject ? 6 : 12}
            style={{ margin: '10px 0' }}
          >
            <Button
              text='Accepter les cookies nécessaires au fonctionnement du service'
              onClick={() => {
                this.cookies.set(
                  `${this.props.name}-cookies`,
                  '1',
                  this.props.options
                )

                this.setState({ show: false }, () => {
                  if (this.props.onAccept) this.props.onAccept()
                })
              }}
            />
          </Col>
          {this.props.isReject ? (
            <Col xs={12} md={6} style={{ margin: '10px 0' }}>
              <Button
                text='Refuser'
                outline
                onClick={() => {
                  this.cookies.set(
                    `${this.props.name}-cookies`,
                    '0',
                    this.props.options
                  )

                  this.setState({ show: false }, () => {
                    if (this.props.onReject) this.props.onReject()
                  })
                }}
              />
            </Col>
          ) : null}
          <a
            style={{
              color: 'gray',
              marginTop: '10px',
              marginLeft: 'auto',
              textDecoration: 'none'
            }}
            onClick={() => {
              this.cookies.set(
                `${this.props.name}-cookies`,
                '2',
                this.props.options
              )

              this.setState({ show: false }, () => {
                if (this.props.onContinue) this.props.onContinue()
              })
            }}
          >
            Continuer sans accepter
          </a>
        </Row>
      </Modal>
    )
  }
}
