import React from 'react'
import style from './style.css'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

export default class TabsEditHeader extends React.Component {
  render() {
    return (
      <div className={classNames(style.tabs_edit_header, this.props.className)}>
        <div>{this.props.children}</div>
        <div>
          <div
            className='cursor-pointer'
            style={{
              backgroundColor: 'var(--primary-color)',
              display: 'flex',
              width: '40px',
              height: '40px',
              margin: 'auto',
              borderRadius: '10px'
            }}
            onClick={() =>
              this.props.onClickAdd ? this.props.onClickAdd() : null
            }
          >
            <FontAwesomeIcon className='m-auto white' icon={faPlus} />
          </div>
        </div>
      </div>
    )
  }
}
