import React, { createRef } from 'react'
import style from './style.css'
import classNames from 'classnames'

export default class Card extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0
    }
    this.refTitle = createRef()
  }

  componentDidMount() {
    this.setState({ width: this.getWith() })
  }

  componentDidUpdate(prevProps, prevState) {
    const width = this.getWith()
    if (width !== prevState.width) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ width })
    }
  }

  getWith = () => {
    if (this.refTitle.current) {
      return this.refTitle.current.offsetWidth
    } else {
      return 0
    }
  }

  render() {
    return (
      <div
        style={
          this.props.border
            ? {
                borderLeft: `solid ${this.props.border} 16px`
              }
            : {}
        }
        className={classNames(style.card, this.props.className)}
      >
        {this.props.disabled ? (
          <div className={classNames(style.card_disabled)} />
        ) : null}
        {this.props.imgLeft ? (
          <div
            style={{
              overflow: 'hidden',
              width: this.props.customWidthImgLeft || '100%',
              flexGrow: 0,
              flexShrink: 0
            }}
          >
            <img
              style={{ height: '100%' }}
              src={this.props.imgLeft}
              alt='left'
            />
          </div>
        ) : null}
        {this.props.isLoad ? (
          <div className={style.card_loader_container}>
            <div
              className={classNames(
                style.loader_element,
                this.props.classNameChildren
              )}
            >
              <div className={style.loader}>
                <span />
                <span />
                <span />
                <span />
              </div>
              {this.props.loaderText ? <p>{this.props.loaderText}</p> : null}
            </div>
          </div>
        ) : null}
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            className={style.card_top_border}
            style={{
              width: this.state.width,
              ...(this.props.borderTopColor
                ? { backgroundColor: this.props.borderTopColor }
                : {})
            }}
          />
          {this.props.title ? (
            <div className={style.card_header}>
              <div className={style.card_header_left}>
                <h2 ref={this.refTitle}>{this.props.title}</h2>
              </div>
              <div className={style.card_header_right}>
                <div
                  className={classNames(
                    style.card_header_action,
                    this.props.disableStyleAction
                      ? style.card_header_action_disable
                      : ''
                  )}
                  onClick={this.props.onClick}
                >
                  {this.props.action}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <div
            className={classNames(
              style.card_body,
              this.props.classNameChildren
            )}
          >
            {this.props.children}
          </div>
        </div>
        {this.props.imgRight ? (
          <div
            style={{
              overflow: 'hidden',
              width: this.props.customWidthImgRight || '100%',
              flexGrow: 0,
              flexShrink: 0
            }}
          >
            <img
              style={{ height: '100%' }}
              src={this.props.imgRight}
              alt='right'
            />
          </div>
        ) : null}
      </div>
    )
  }
}
