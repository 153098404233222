import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimes,
  faCheck,
  faUpDownLeftRight
} from '@fortawesome/free-solid-svg-icons'
import style from './style.css'
import classNames from 'classnames'

export default class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMove: false,
      xStart: null,
      yStart: null,
      x: null,
      y: null
    }
  }

  componentDidMount() {
    if (this.props.show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    window.addEventListener('mouseup', this.handleMouseUp)
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUp)
  }

  componentDidUpdate() {
    if (this.props.show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }

  handleMouseUp = () => {
    this.setState(
      {
        isMove: false
      },
      () => {
        window.removeEventListener('mousemove', this.updateMousePosition)
      }
    )
  }

  updateMousePosition = (e) => {
    this.setState({ x: e.clientX, y: e.clientY })
  }

  handleClickModal(e) {
    if (e.target === e.currentTarget && !this.props.disableClose) {
      this.props.onClose(e)
    }
  }

  render() {
    if (!this.props.show) {
      return null
    }

    var modalContainerClass = classNames(
      {
        [style.large]: !!this.props.large,
        [style.no_background]: !!this.props.noBackground
      },
      style.modal_container,
      this.props.className
    )

    return (
      <div
        className={style.modal}
        onMouseDown={(e) => this.handleClickModal(e)}
      >
        <div
          className={modalContainerClass}
          style={{
            left:
              this.state.x && this.state.xStart
                ? this.state.x - this.state.xStart
                : 0,
            top:
              this.state.y && this.state.yStart
                ? this.state.y - this.state.yStart
                : 0
          }}
        >
          <div
            className={style.modal_move}
            onMouseDown={(e) => {
              this.setState(
                {
                  isMove: true,
                  xStart: this.state.xStart || e.clientX,
                  yStart: this.state.yStart || e.clientY
                },
                () =>
                  window.addEventListener('mousemove', this.updateMousePosition)
              )
            }}
          >
            {this.props.isMove ? (
              <FontAwesomeIcon
                className={this.state.isMove ? 'red' : ''}
                icon={faUpDownLeftRight}
              />
            ) : (
              ''
            )}
          </div>
          <div className={style.modal_valid} onClick={this.props.onValid}>
            {this.props.onValid ? <FontAwesomeIcon icon={faCheck} /> : ''}
          </div>
          {!this.props.disableClose ? (
            <div className={style.modal_close} onClick={this.props.onClose}>
              {this.props.iconClose ? (
                this.props.iconClose
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              )}
            </div>
          ) : null}
          <div className={style.modal_header}>
            <h2>{this.props.title}</h2>
          </div>
          <div className={style.modal_body}>{this.props.children}</div>
        </div>
      </div>
    )
  }
}
